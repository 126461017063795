import { Grid } from "@mui/material";
import AnalyticsChartB from "./AnalyticsChartB";
import AnalyticsChartA from "./AnalyticsChartA";
import PageAnalytics from "./PageAnalytics";

const GoogleAnalytics = () => {
  return (
    <Grid container direction="row" spacing={2} columns={12}>
      <Grid item xs={7}>
        <AnalyticsChartA />
      </Grid>
      <Grid item xs={5}>
        <AnalyticsChartB />
      </Grid>
      <Grid item xs={12}>
        <PageAnalytics />
      </Grid>
    </Grid>
  );
};

export default GoogleAnalytics;
