// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Checkbox,
//   Paper,
//   TablePagination,
//   TextField,
//   Typography,
//   Select,
//   MenuItem,
//   CircularProgress,
// } from "@mui/material";
// import { getRequest } from "src/app/httpClient/axiosClient";
// import { GENERAL_SETTINGS } from "src/app/config/endpoints";
// import useSearchDebounce from "src/utils/hooks/textDebounce";
// import { formatTime } from "src/utils/helpers/functions";

// const durationOptions = [
//   "Custom",
//   "Today",
//   "Yesterday",
//   "This week (Sun - Today)",
//   "Last week (Sun - Sat)",
//   "Last 7 days",
//   "Last 28 days",
//   "Last 30 days",
//   "Last 90 days",
//   "Last 12 months",
//   "Last calendar year",
//   "This year (Jan - Today)",
// ];

// const columnHeaders = [
//   { label: "Page path and screen class", align: "left" },
//   { label: "Views", align: "right" },
//   { label: "Active Users", align: "right" },
//   { label: "Views per active user", align: "right" },
//   { label: "Average engagement time per user", align: "right" },
//   {
//     label: "Event count",
//     align: "right",
//   },
//   // { label: "Total revenue", align: "right" },
// ];

// const PageAnalytics = () => {
//   const [search, setSearch] = useState("");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [eventFilter, setEventFilter] = useState("all");
//   const [duration, setDuration] = useState("Last 28 days");
//   const [pageData, setPageData] = useState([]);
//   const [totalCount, setTotalCount] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();

//   // Filter data based on search query
//   // const filteredData = mockData.filter((row) =>
//   //   row.path.toLowerCase().includes(search.toLowerCase())
//   // );

//   // Paginated data
//   // const paginatedData = filteredData.slice(
//   //   page * rowsPerPage,
//   //   page * rowsPerPage + rowsPerPage
//   // );

//   const handleSearchChange = (event) => {
//     setSearch(event.target.value);
//     setSearchTextDebounce(event.target.value);
//     setPage(0);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleEventFilterChange = (event) => {
//     setEventFilter(event.target.value);
//   };

//   const handleDurationChange = (event) => {
//     setDuration(event.target.value);
//   };

//   const getPageData = async () => {
//     const offset = page * rowsPerPage;
//     const url = `${
//       GENERAL_SETTINGS.GET_PAGE_DATA
//     }?duration=${duration}&offset=${offset}&limit=${rowsPerPage}${
//       search?.length > 0 ? `&search=${search}` : ""
//     }`;

//     try {
//       setLoading(true);
//       const response = await getRequest(url);
//       if (response.data.data && response.data.total_count !== undefined) {
//         setPageData(response.data.data);
//         setTotalCount(response.data.total_count);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getPageData();
//   }, [duration, page, rowsPerPage, searchTextDebounce]);

//   return (
//     <Box
//       sx={{
//         padding: "20px",
//         border: "1px solid #e0e0e0",
//         borderRadius: "8px",
//         width: "100%",
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         Page Analytics
//       </Typography>
//       {/* Search and Duration Filter */}
//       <Box display="flex" gap={2} alignItems="center" mb={2}>
//         {/* <TextField
//           label="Search by page path"
//           variant="outlined"
//           size="small"
//           fullWidth
//           value={search}
//           onChange={handleSearchChange}
//         /> */}
//         <Box position="relative" flexGrow={1}>
//           <TextField
//             label="Search by page path"
//             variant="outlined"
//             size="small"
//             fullWidth
//             value={search}
//             onChange={handleSearchChange}
//           />
//           {loading && (
//             <CircularProgress
//               size={24}
//               sx={{
//                 position: "absolute",
//                 top: "50%",
//                 right: "10px",
//                 marginTop: "-12px",
//               }}
//             />
//           )}
//         </Box>
//         <Select
//           value={duration}
//           onChange={handleDurationChange}
//           displayEmpty
//           size="small"
//           sx={{ minWidth: 180 }}
//         >
//           {durationOptions.map((option) => (
//             <MenuItem key={option} value={option}>
//               {option}
//             </MenuItem>
//           ))}
//         </Select>
//       </Box>
//       <TableContainer component={Paper}>
//         <Table aria-label="analytics table">
//           <TableHead>
//             <TableRow>
//               {columnHeaders.map((column, index) => {
//                 if (column?.label === "Event count") {
//                   return (
//                     <TableCell align="right" key={index}>
//                       {column.label}
//                       {/* <Box
//                         display="flex"
//                         flexDirection="column"
//                         alignItems="center"
//                       >
//                         <Typography variant="body2">Event count</Typography>
//                         <Select
//                           value={eventFilter}
//                           onChange={handleEventFilterChange}
//                           displayEmpty
//                           size="small"
//                           sx={{ mt: 1, width: 120 }}
//                         >
//                           <MenuItem value="all">All Events</MenuItem>
//                           <MenuItem value="click">Click</MenuItem>
//                           <MenuItem value="scroll">Scroll</MenuItem>
//                           <MenuItem value="formSubmit">Form Submit</MenuItem>
//                         </Select>
//                       </Box> */}
//                     </TableCell>
//                   );
//                 } else {
//                   return (
//                     <TableCell key={index} align={column.align}>
//                       {column.label}
//                     </TableCell>
//                   );
//                 }
//               })}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {pageData?.map((row, index) => (
//               <TableRow key={index}>
//                 <TableCell component="th" scope="row">
//                   {row?.path}
//                 </TableCell>
//                 <TableCell align="right">{row?.screenPageViews}</TableCell>
//                 <TableCell align="right">{row?.activeUsers}</TableCell>
//                 <TableCell align="right">
//                   {row?.viewsPerActive?.toFixed(2) || 0}
//                 </TableCell>
//                 <TableCell align="right">
//                   {formatTime(row?.engagementTime) || 0}
//                 </TableCell>
//                 <TableCell align="right">{row?.eventCount || 0}</TableCell>
//                 {/* <TableCell align="right">{row.revenue}</TableCell> */}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 50, 100, 250]}
//           component="div"
//           count={totalCount}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableContainer>
//     </Box>
//   );
// };

// export default PageAnalytics;

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material";
import { getRequest } from "src/app/httpClient/axiosClient";
import { GENERAL_SETTINGS } from "src/app/config/endpoints";
import useSearchDebounce from "src/utils/hooks/textDebounce";
import { formatTime } from "src/utils/helpers/functions";
import { createNotification } from "src/common/createNotification";

const durationOptions = [
  "Custom",
  "Today",
  "Yesterday",
  "This week (Sun - Today)",
  "Last week (Sun - Sat)",
  "Last 7 days",
  "Last 28 days",
  "Last 30 days",
  "Last 90 days",
  "Last 12 months",
  "Last calendar year",
  "This year (Jan - Today)",
];

const columnHeaders = [
  { label: "Page path and screen class", align: "left" },
  { label: "Views", align: "right" },
  { label: "Active Users", align: "right" },
  { label: "Views per active user", align: "right" },
  { label: "Average engagement time per user", align: "right" },
  {
    label: "Event count",
    align: "right",
  },
  // { label: "Total revenue", align: "right" },
];

const PageAnalytics = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [eventFilter, setEventFilter] = useState("all");
  const [duration, setDuration] = useState("Last 28 days");
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setSearchTextDebounce(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEventFilterChange = (event) => {
    setEventFilter(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
    if (event.target.value === "Custom") {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const handleCustomDateRange = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    setDateRange((prev) => ({ ...prev, [fieldName]: value }));
  };

  const getPageData = async () => {
    if (duration === "Custom") {
      if (!dateRange?.startDate || !dateRange?.endDate) {
        createNotification("error", "", "Please select valid date range");
        return;
      }

      const start_date = new Date(dateRange?.startDate);
      const end_date = new Date(dateRange?.endDate);
      if (end_date < start_date) {
        createNotification("error", "", "Please select valid date range");
        return;
      }
    }

    const offset = page * rowsPerPage;
    const url = `${
      GENERAL_SETTINGS.GET_PAGE_DATA
    }?duration=${duration}&offset=${offset}&limit=${rowsPerPage}${
      search?.length > 0 ? `&search=${search}` : ""
    }${
      duration === "Custom"
        ? `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`
        : ""
    }`;

    try {
      setLoading(true);
      const response = await getRequest(url);
      if (response.data.data && response.data.total_count !== undefined) {
        setPageData(response.data.data);
        setTotalCount(response.data.total_count);
        setDateRange({
          startDate: response?.data?.startDate,
          endDate: response?.data?.endDate,
        });
      }
    } catch (error) {
      createNotification("error", "", "Getting error while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (duration !== "Custom") {
      getPageData();
    }
  }, [duration, page, rowsPerPage, searchTextDebounce]);

  return (
    <Box
      sx={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Page Analytics
      </Typography>
      {/* Search and Duration Filter */}
      <Box display="flex" gap={2} alignItems="center" mb={2}>
        {/* <TextField
          label="Search by page path"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        /> */}
        {/* <Box position="relative" flexGrow={1}>
          <TextField
            label="Search by page path"
            variant="outlined"
            size="small"
            fullWidth
            value={search}
            onChange={handleSearchChange}
          />
          {loading && search?.length > 0 && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                right: "10px",
                marginTop: "-12px",
              }}
            />
          )}
        </Box> */}
        <TextField
          label="Search by page path"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
        <Select
          value={duration}
          onChange={handleDurationChange}
          displayEmpty
          size="small"
          sx={{ minWidth: 180 }}
        >
          {durationOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <>
          <TextField
            label="Start Date"
            type="date"
            value={dateRange?.startDate}
            onChange={handleCustomDateRange}
            name="startDate"
            disabled={duration !== "Custom"}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ minWidth: 150 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={dateRange?.endDate}
            disabled={duration !== "Custom"}
            name="endDate"
            onChange={handleCustomDateRange}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ minWidth: 150 }}
          />
          {duration === "Custom" && (
            <Button variant="outlined" disabled={loading} onClick={getPageData}>
              {loading ? "PLEASE WAIT..." : "Apply"}
            </Button>
          )}
        </>
      </Box>

      {loading ? (
        <Box
          sx={{
            // height: "400px",
            display: "flex", // Enable Flexbox layout
            justifyContent: "center", // Center horizontally
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : pageData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="analytics table">
            <TableHead>
              <TableRow>
                {columnHeaders.map((column, index) => {
                  if (column?.label === "Event count") {
                    return (
                      <TableCell align="right" key={index}>
                        {column.label}
                        {/* <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body2">Event count</Typography>
                        <Select
                          value={eventFilter}
                          onChange={handleEventFilterChange}
                          displayEmpty
                          size="small"
                          sx={{ mt: 1, width: 120 }}
                        >
                          <MenuItem value="all">All Events</MenuItem>
                          <MenuItem value="click">Click</MenuItem>
                          <MenuItem value="scroll">Scroll</MenuItem>
                          <MenuItem value="formSubmit">Form Submit</MenuItem>
                        </Select>
                      </Box> */}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={index} align={column.align}>
                        {column.label}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row?.path}
                  </TableCell>
                  <TableCell align="right">{row?.screenPageViews}</TableCell>
                  <TableCell align="right">{row?.activeUsers}</TableCell>
                  <TableCell align="right">
                    {row?.viewsPerActive?.toFixed(2) || 0}
                  </TableCell>
                  <TableCell align="right">
                    {formatTime(row?.engagementTime) || 0}
                  </TableCell>
                  <TableCell align="right">{row?.eventCount || 0}</TableCell>
                  {/* <TableCell align="right">{row.revenue}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 250]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Typography variant="subtitle1" textAlign="center">
          No data found
        </Typography>
      )}
    </Box>
  );
};

export default PageAnalytics;
