import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Container, Typography } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { getDashboard } from "src/api/api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../utils/helpers/functions";
import GoogleAnalytics from "./GoogleAnalytics";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [data, setdata] = useState({});
  const [countLoading, setcountLoading] = useState(false);
  const getList = async () => {
    setcountLoading(true);
    try {
      const data = await getDashboard();
      setdata(data.data);
      setcountLoading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Popsmoke media </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/news" style={{ textDecoration: "none" }}>
              <AppWidgetSummary
                title="Total Live News"
                loading={countLoading}
                total={data?.publishNewsCount}
                color="info"
                icon={"bxs:news"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/archieved-news" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Archived News"
                loading={countLoading}
                total={data?.archivedCount}
                color="warning"
                icon={"ic:round-archive"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/e-commerce/product/listing" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Products"
                loading={countLoading}
                total={data?.productCount}
                color="secondary"
                icon={"ic:round-shopping-cart"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/e-commerce/product/listing" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Orders"
                loading={countLoading}
                total={data?.orderCount}
                color="error"
                icon={"ic:round-store"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/subscribers" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Subscribers"
                loading={countLoading}
                total={data?.subscribeCount}
                color="info"
                icon={"ic:round-person"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/contacts" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Contact Queries"
                loading={countLoading}
                total={data?.contactCount}
                color="warning"
                icon={"ic:round-star"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Link to="/dashboard/author" style={{ textDecoration: "none" }} >
              <AppWidgetSummary
                title="Total Authors"
                loading={countLoading}
                total={data?.authorCount}
                color="secondary"
                icon={"ic:round-assessment"}
              />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Media Uploads"
              loading={countLoading}
              total={data?.mediaCount}
              color="error"
              icon={"material-symbols:perm-media-rounded"}
            />
          </Grid>

            <Grid item xs={12}>
              <GoogleAnalytics />
            </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Advertisment"
              total={21}
              color="warning"
              icon={"ri:advertisement-fill"}
            />
          </Grid>  */}
        </Grid>
      </Container>
    </>
  );
}
